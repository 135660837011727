<template>
  <PxHeader :activeSearchMobile="false" />
  <section class="bg-agrogo-green-2 sizeMinAllView">
    <div class="container-error-404">
      <img
        class="w-14 md:w-20 mb-6 md:mb-2"
        srcset="@/assets/images/lupa404.svg"
        src="@/assets/images/lupa404.png"
        alt=""
      />
      <router-link
        :to="isPreLaunch ? '/inicio-de-sesion' : '/inicio'"
        class="btn-primary-yellow btn-back w-48 h-8 mb-9 md:h-10 md:mb-7"
      >
        <span class="font-nexa font-bold text-base">Volver al inicio</span>
      </router-link>
      <p
        class="font-nexa font-bold text-white text-base mb-6 md:text-2xl md:mb-10"
      >
        Ups! parece que hubo un error
      </p>
      <img
        class="w-20 md:w-40"
        srcset="@/assets/images/404.svg"
        src="@/assets/images/404.png"
        alt=""
      />
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import PxHeader from "@/components/header/PxHeader";

export default {
  name: "Error404",
  components: {
    PxHeader,
  },
  computed: {
    ...mapState("preLaunch", ["isPreLaunch"]),
  },
  mounted() {
    window.scroll(0, 0);
  },
};
</script>
<style lang="css" scoped>
.btn-back {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
